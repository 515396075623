import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanMatch,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, Subject, map, of, switchMap, takeUntil } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { UserRoleService } from '../../../role-service.service';
import { Tenant } from 'app/modules/types/tenant.types';
import { TenantsService } from 'app/layout/common/tenants/tenants.service';
import { RestApiService } from 'app/modules/rest/rest-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanMatch, CanActivate {
  tenant: Tenant;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _roleService: UserRoleService,
    private _tenantService: TenantsService,
    private _apiService: RestApiService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can match
   *
   * @param route
   * @param segments
   */
  canMatch(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._check(segments);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let role = route.data.role;
    if (role == null) return true;

    this._tenantService
      .getTenantObservable()
      .pipe(
        takeUntil(this._unsubscribeAll),
        switchMap(tenant => {
          if (tenant) {
            return this._roleService.getData(tenant.name).then(roles => {
              if (roles.includes(role)) return of(true);
              this._router.navigate(['/forbidden']);
              return of(false);
            });
          }
        })
      )
      .subscribe(result => {
        // Here you can use the result as needed
        console.log('Result:', result);
      });
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param segments
   * @private
   */
  private _check(segments: UrlSegment[]): Observable<boolean | UrlTree> {
    // Check the authentication status
    return this._authService.check().pipe(
      switchMap(authenticated => {
        // If the user is not authenticated...
        if (!authenticated) {
          // Redirect to the sign-in page with a redirectUrl param
          const redirectURL = `/${segments.join('/')}`;
          const urlTree = this._router.parseUrl(
            `sign-in?redirectURL=${redirectURL}`
          );

          return of(urlTree);
        }

        // Allow the access
        return of(true);
      })
    );
  }
}
