import { enableProdMode } from '@angular/core';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
import { ApplicationSettings } from 'app/modules/types/appConfiguration';
import { environment } from 'environments/environment';
import { FirebaseOptions } from 'firebase/app';

if (environment.production) {
  enableProdMode();
}

function getFirebaseConfig() {
  return environment.production
    ? fetch('config.prod.json')
        .then(res => {
          return res.json(); // Parse the JSON data
        })
        .then((data: ApplicationSettings) => {
          return data.firebase;
        })
        .catch(error => {
          console.error('Fetch Error:', error);
        })
    : Promise.resolve(environment.firebase);
}

(async () => {
  const firebaseConfig = await getFirebaseConfig();
  const firebaseModule = AngularFireModule.initializeApp(
    firebaseConfig as FirebaseOptions
  );

  platformBrowserDynamic([
    { provide: FIREBASE_OPTIONS, useValue: firebaseConfig },
    { provide: AngularFireModule, useValue: firebaseModule },
  ])
    .bootstrapModule(AppModule)
    .catch(console.error);
})();
