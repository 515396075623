import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseUtilsService } from '@fuse/services/utils/utils.service';
import { TenantsService } from 'app/layout/common/tenants/tenants.service';
import { Tenant } from 'app/modules/types/tenant.types';
import { RestApiService } from 'app/modules/rest/rest-api.service';

@Component({
  selector: 'fuse-horizontal-navigation',
  templateUrl: './horizontal.component.html',
  styleUrls: ['./horizontal.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'fuseHorizontalNavigation',
})
export class FuseHorizontalNavigationComponent
  implements OnChanges, OnInit, OnDestroy
{
  private _navigation: FuseNavigationItem[];
  tenant: Tenant;
  showChat = false;
  isAdmin = false;
  navigationChanged = false;
  @Input() name: string = this._fuseUtilsService.randomId();
  @Input()
  set navigation(value: FuseNavigationItem[]) {
    this._navigation = value;
    if (this.tenant && this.tenant.name && this._navigation)
      this.addTenantToRoutes(this.tenant.name);
  }

  get navigation(): FuseNavigationItem[] {
    // You can perform additional logic here before returning the property
    return this._navigation;
  }

  onRefreshed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseUtilsService: FuseUtilsService,
    private _tenantsService: TenantsService,
    private restApiService: RestApiService
  ) {
    this._tenantsService
      .getTenantObservable()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((tenants: Tenant) => {
        this.tenant = tenants;
        this.getUserRoles();
      });
  }
  private getUserRoles(): void {
    this.restApiService
      .getUserRoles(this.tenant.name)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(roles => {
        this.showChat = roles.includes('CHAT');
        this.isAdmin = roles.includes('CUSTOMER_ADMIN');
      });
  }

  private addTenantToRoutes(id: string): void {
    if (id) {
      const routesToUpdate = ['chat', 'question', 'dashboard', 'setting'];

      routesToUpdate.forEach(routeId => {
        const route = this._navigation.find(h => h.id === routeId);
        if (route) {
          route.link = `/${id}/${routeId}`;
        }
      });
    }
  }

  showTab(navigation: FuseNavigationItem): boolean {
    if (navigation.meta && navigation.meta.role === 'CHAT' && !this.showChat) {
      return false;
    }
    if (
      navigation.meta &&
      navigation.meta.role === 'CUSTOMER_ADMIN' &&
      !this.isAdmin
    ) {
      return false;
    }

    return true;
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On changes
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // Navigation
    if ('navigation' in changes) {
      // Mark for check
      this._changeDetectorRef.markForCheck();
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    // Make sure the name input is not an empty string
    if (this.name === '') {
      this.name = this._fuseUtilsService.randomId();
    }

    // Register the navigation component
    this._fuseNavigationService.registerComponent(this.name, this);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Deregister the navigation component from the registry
    this._fuseNavigationService.deregisterComponent(this.name);

    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Refresh the component to apply the changes
   */
  refresh(): void {
    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Execute the observable
    this.onRefreshed.next(true);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
