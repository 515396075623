import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { QuotaAlertComponent } from './quota-alert.component';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';

@NgModule({
  declarations: [QuotaAlertComponent],
  imports: [SharedModule, TranslocoModule, TranslocoLocaleModule],
  exports: [QuotaAlertComponent],
})
export class QuotaAlertModule {}
