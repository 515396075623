/* eslint-disable */
import { DateTime } from 'luxon';

/* Get the current instant */
const now = DateTime.now();

export const analytics = {
  totalCalls: {
    amount: 1500,
    labels: [
      now.minus({ days: 47 }).toFormat('dd MMM') +
        ' - ' +
        now.minus({ days: 40 }).toFormat('dd MMM'),
      now.minus({ days: 39 }).toFormat('dd MMM') +
        ' - ' +
        now.minus({ days: 32 }).toFormat('dd MMM'),
      now.minus({ days: 31 }).toFormat('dd MMM') +
        ' - ' +
        now.minus({ days: 24 }).toFormat('dd MMM'),
      now.minus({ days: 23 }).toFormat('dd MMM') +
        ' - ' +
        now.minus({ days: 16 }).toFormat('dd MMM'),
      now.minus({ days: 15 }).toFormat('dd MMM') +
        ' - ' +
        now.minus({ days: 8 }).toFormat('dd MMM'),
      now.minus({ days: 7 }).toFormat('dd MMM') +
        ' - ' +
        now.toFormat('dd MMM'),
    ],
    series: [
      {
        name: 'Total Calls',
        data: [800, 1000, 1200, 1500, 1300, 1500],
      },
    ],
  },
  bookings: {
    amount: 500,
    labels: [
      now.minus({ days: 31 }).toFormat('dd MMM') +
        ' - ' +
        now.minus({ days: 24 }).toFormat('dd MMM'),
      now.minus({ days: 23 }).toFormat('dd MMM') +
        ' - ' +
        now.minus({ days: 16 }).toFormat('dd MMM'),
      now.minus({ days: 15 }).toFormat('dd MMM') +
        ' - ' +
        now.minus({ days: 8 }).toFormat('dd MMM'),
      now.minus({ days: 7 }).toFormat('dd MMM') +
        ' - ' +
        now.toFormat('dd MMM'),
    ],
    series: [
      {
        name: 'Bookings',
        data: [150, 400, 350, 600],
      },
    ],
  },
  callbacks: {
    amount: 650,
    labels: [
      now.minus({ days: 31 }).toFormat('dd MMM') +
        ' - ' +
        now.minus({ days: 24 }).toFormat('dd MMM'),
      now.minus({ days: 23 }).toFormat('dd MMM') +
        ' - ' +
        now.minus({ days: 16 }).toFormat('dd MMM'),
      now.minus({ days: 15 }).toFormat('dd MMM') +
        ' - ' +
        now.minus({ days: 8 }).toFormat('dd MMM'),
      now.minus({ days: 7 }).toFormat('dd MMM') +
        ' - ' +
        now.toFormat('dd MMM'),
    ],
    series: [
      {
        name: 'Callbacks',
        data: [250, 500, 480, 730],
      },
    ],
  },
  topQuestions: {
    uniqueVisitors: 1500,
    series: [40, 20, 8, 7, 15],
    labels: ['Booking', 'Check-in', 'Roomservice', 'Breakfast', 'Other'],
  },
  recentTransactions: [
    {
      id: '1b6fd296-bc6a-4d45-bf4f-e45519a58cf5',
      callerId: '+49 151 1634 1654',
      duration: '3m 30s',
      question: 'Booking',
      state: 'completed',
      start: '2023-02-05T10:22:37.274Z',
    },
    {
      id: '2dec6074-98bd-4623-9526-6480e4776569',
      callerId: '+49 151 1634 1654',
      duration: '2m 30s',
      question: 'Breakfast',
      state: 'completed',
      start: '2023-02-05T09:52:24.461Z',
    },
    {
      id: 'ae7c065f-4197-4021-a799-7a221822ad1d',
      callerId: '+49 151 1634 1654',
      duration: '3m 30s',
      question: 'Booking',
      state: 'callback pending',
      start: '2023-02-05T09:52:14.304Z',
    },
    {
      id: '0c43dd40-74f6-49d5-848a-57a4a45772ab',
      callerId: '+49 151 1634 1654',
      duration: '30s',
      question: 'Roomservice',
      state: 'transfer to restaurant',
      start: '2023-02-05T09:32:16.111Z',
    },
    {
      id: 'e5c9f0ed-a64c-4bfe-a113-29f80b4e162c',
      callerId: '+49 151 1634 1654',
      duration: '1m 30s',
      question: 'Massage',
      state: 'callback completed',
      start: '2023-02-05T09:13:23.064Z',
    },
    {
      id: 'e5c9f0ed-a64c-4bfe-a113-29f80b4e162c',
      callerId: '+49 151 1634 1654',
      duration: '1m 30s',
      question: 'Massage',
      state: 'callback pending',
      start: '2023-02-04T16:13:23.064Z',
    },
  ],
};
