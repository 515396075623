import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { RestApiService } from 'app/modules/rest/rest-api.service';
import { Tenant } from 'app/modules/types/tenant.types';
import {
  Observable,
  ReplaySubject,
  filter,
  map,
  of,
  switchMap,
  take,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TenantsService {
  constructor(
    private route: ActivatedRoute,
    private _apiService: RestApiService
  ) {}

  private selectedTenant: ReplaySubject<Tenant> = new ReplaySubject<Tenant>(
    null
  );

  getTenantObservable(): ReplaySubject<Tenant> {
    return this.selectedTenant;
  }

  updateTenant(tenant: Tenant): void {
    this.selectedTenant.next(tenant);
  }

  getActiveTenant(): Observable<Tenant | null> {
    return this.route.paramMap.pipe(
      switchMap((params: Params) => {
        const tenantId = params.get('tenantId');
        if (tenantId) {
          return this._apiService.getTenants();
        } else {
          return of(null);
        }
      }),
      filter(tenants => tenants !== null),
      take(1),
      map((tenants: Tenant[]) => (tenants.length > 0 ? tenants[0] : null))
    );
  }
}
