import { ApplicationSettings } from 'app/modules/types/appConfiguration';

export const environment: ApplicationSettings = {
  firebase: {
    projectId: '',
    appId: '',
    storageBucket: '',
    apiKey: '',
    authDomain: '',
    messagingSenderId: '',
    measurementId: '',
  },
  speechServices: {
    key: '',
    region: '',
  },
  production: true,
  adminBaseUrl: '',
  nlpBaseUrl: '',
  statementUrl: 'https://pay.aicall.io/p/login/5kA9E79m01GfgikdQQ',
};
