/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'dashboard',
    type: 'basic',
    icon: 'heroicons_outline:chart-bar',
    link: '/dashboard',
    meta: {
      role: 'CUSTOMER_ADMIN',
    },
  },
  {
    id: 'question',
    title: 'question',
    type: 'basic',
    icon: 'heroicons_outline:question-mark-circle',
    link: '/:tenantId/question',
    meta: {
      role: 'CUSTOMER_ADMIN',
    },
  },
  {
    id: 'chat',
    title: 'chat',
    type: 'basic',
    icon: 'heroicons_outline:chat',
    link: '/:tenantId/chat',
    meta: {
      role: 'CHAT',
    },
  },
];
export const compactNavigation: FuseNavigationItem[] = [];
export const futuristicNavigation: FuseNavigationItem[] = [];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'dashboard',
    type: 'basic',
    icon: 'heroicons_outline:chart-bar',
    link: '/dashboard',
    meta: {
      role: 'CUSTOMER_ADMIN',
    },
  },
  {
    id: 'question',
    title: 'question',
    type: 'basic',
    icon: 'heroicons_outline:question-mark-circle',
    link: '/:tenantId/question',
    meta: {
      role: 'CUSTOMER_ADMIN',
    },
  },
  {
    id: 'chat',
    title: 'chat',
    type: 'basic',
    icon: 'heroicons_outline:chat',
    link: '/:tenantId/chat',
    meta: {
      role: 'CHAT',
    },
  },
];
