<div
  *ngIf="!!quota"
  class="py-2 px-4 items-center bg-primary-500 text-white leading-none rounded-full flex inline-flex"
  role="alert">
  <span
    class="font-semibold text-left flex-auto"
    transloco="quotaAlert.message"
    [translocoParams]="{
      remainingMinutes: this.remainingMinutes,
      freeMinutes: this.freeMinutes
    }">
  </span>
</div>
