import { Injectable } from '@angular/core';
import { cloneDeep, first } from 'lodash-es';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import {
  compactNavigation,
  defaultNavigation,
  futuristicNavigation,
  horizontalNavigation,
} from 'app/mock-api/common/navigation/data';
import { TranslocoService } from '@ngneat/transloco';
import { UserRoles } from '../../../role-service.service';
import { TenantsService } from 'app/layout/common/tenants/tenants.service';
import { Tenant } from 'app/modules/types/tenant.types';

@Injectable({
  providedIn: 'root',
})
export class NavigationMockApi {
  private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
  private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
  private readonly _futuristicNavigation: FuseNavigationItem[] =
    futuristicNavigation;
  private readonly _horizontalNavigation: FuseNavigationItem[] =
    horizontalNavigation;

  /**
   * Constructor
   */
  constructor(
    private _fuseMockApiService: FuseMockApiService,
    private translocoService: TranslocoService,
    private _userRoles: UserRoles
  ) {
    // Register Mock API handlers
    this.registerHandlers();
    this._userRoles.getRoles().subscribe(roles => {
      this._horizontalNavigation.forEach(item => {
        this.checkRole(item, roles);
      });
      this._defaultNavigation.forEach(item => {
        this.checkRole(item, roles);
      });
    });
  }

  private checkRole(item: FuseNavigationItem, roles: string[]) {
    if (item.meta?.role !== undefined) {
      if (!roles.includes(item.meta.role)) {
        item.hidden = item => true;
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Navigation - GET
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService.onGet('api/common/navigation').reply(() => {
      // Fill compact navigation children using the default navigation
      this._compactNavigation.forEach(compactNavItem => {
        compactNavItem.title = this.translocoService.translate(
          compactNavItem.title
        );
        this._defaultNavigation.forEach(defaultNavItem => {
          if (defaultNavItem.id === compactNavItem.id) {
            compactNavItem.children = cloneDeep(defaultNavItem.children);
          }
        });
      });

      // Fill futuristic navigation children using the default navigation
      this._futuristicNavigation.forEach(futuristicNavItem => {
        this._defaultNavigation.forEach(defaultNavItem => {
          if (defaultNavItem.id === futuristicNavItem.id) {
            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
          }
        });
      });

      // Fill horizontal navigation children using the default navigation
      this._horizontalNavigation.forEach(horizontalNavItem => {
        horizontalNavItem.title = this.translocoService.translate(
          horizontalNavItem.title
        );
        this._defaultNavigation.forEach(defaultNavItem => {
          if (defaultNavItem.id === horizontalNavItem.id) {
            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
          }
        });
      });

      // Return the response
      return [
        200,
        {
          compact: cloneDeep(this._compactNavigation),
          default: cloneDeep(this._defaultNavigation),
          futuristic: cloneDeep(this._futuristicNavigation),
          horizontal: cloneDeep(this._horizontalNavigation),
        },
      ];
    });
  }
}
