<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
  <fuse-vertical-navigation
    class="bg-primary-900 print:hidden"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
      <!-- Logo -->
      <div class="flex items-center h-20 pt-6 px-8">
        <img
          class="w-60 dark:hidden"
          src="assets/images/logo/aicallsmall.png"
          alt="Logo image" />
      </div>
    </ng-container>
  </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div
  class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card">
  <!-- Header -->
  <div
    class="relative flex justify-center w-full overflow-hidden z-49 bg-primary-700 print:hidden">
    <div
      class="max-w-360 w-full sm:py-3 sm:m-8 sm:mb-0 md:mt-12 md:mx-8 md:pt-4 md:pb-3 sm:rounded-t-xl border-b sm:shadow-2xl overflow-hidden bg-card">
      <!-- Top bar -->
      <div
        class="relative flex flex-auto flex-0 items-center h-16 px-4 md:px-6">
        <!-- Logo -->
        <ng-container *ngIf="!isScreenSmall">
          <div class="flex items-center mx-2">
            <!-- Light version -->
            <img
              class="w-60 dark:hidden"
              src="assets/images/logo/aicallsmall.png"
              alt="Logo image" />
            <!-- Dark version -->
            <img
              class="hidden dark:flex w-60"
              src="assets/images/logo/aicallsmall.png"
              alt="Logo image" />
          </div>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
          <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
          </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
          <tenants></tenants>
          <phone-number></phone-number>
          <button mat-icon-button (click)="onClickQuestion()">
            <mat-icon
              [svgIcon]="'heroicons_outline:question-mark-circle'"></mat-icon>
          </button>
          <ng-container *ngIf="horizontalnavigations() && isAdmin">
            <button
              [matTooltip]="tooltipContent"
              [matTooltipPosition]="'below'"
              mat-icon-button
              routerLinkActive="active"
              (click)="routeToSettingPanel()">
              <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
            </button>
          </ng-container>
          <languages></languages>
          <user></user>
          <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->
          <!--                    <search [appearance]="'bar'"></search>-->
          <!--<shortcuts></shortcuts>
                    <messages></messages>-->
          <!--                    <notifications></notifications>-->
        </div>
      </div>
      <!-- Bottom bar -->
      <ng-container>
        <div
          class="relative flex flex-auto flex-0 items-center justify-between h-16 px-4"
          *ngIf="!isScreenSmall || tenant.noSubscription">
          <fuse-horizontal-navigation
            *ngIf="!isScreenSmall"
            [name]="'mainNavigation'"
            [navigation]="horizontalnavigations()"></fuse-horizontal-navigation>
          <app-quota-alert
            class="ml-auto"
            (quotaVisibilityChanged)="onQuotaVisibilityChange($event)">
          </app-quota-alert>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-auto justify-center w-full sm:px-8">
    <div
      class="flex flex-col flex-auto w-full sm:max-w-360 sm:shadow-xl sm:overflow-hidden bg-default">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
      <router-outlet *ngIf="true"></router-outlet>
    </div>
  </div>

  <!-- Footer -->
  <div class="relative flex justify-center w-full z-49 print:hidden">
    <div
      class="flex items-center max-w-360 w-full h-14 sm:h-20 px-6 md:px-8 sm:shadow-xl border-t bg-card dark:bg-default">
      <span class="font-medium text-secondary"
        >aicall.io &copy; {{ currentYear }}</span
      >
    </div>
  </div>
</div>
