<!-- Link -->
<a *ngIf="selectedTenant" [href]="'tel:' + selectedTenant.phoneNumber">
  <!-- No phone icon -->
  <span class="text-primary font-semibold phone-number">
    {{ selectedTenant.phoneNumber }}
  </span>

  <!-- Phone icon -->
  <!-- <mat-icon svgIcon="heroicons_outline:phone" class="phone-number"></mat-icon>
  <span class="text-primary font-semibold ml-2 phone-number">
    {{ selectedTenant.phoneNumber }}
  </span> -->
</a>
