import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TenantsService } from './tenants.service';
import { Tenant } from 'app/modules/types/tenant.types';
import { RestApiService } from 'app/modules/rest/rest-api.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'tenants',
  templateUrl: './tenants.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'tenants',
})
export class TenantsComponent implements OnInit, OnDestroy {
  tenants: Tenant[];
  selectedTenant: Tenant;
  formControl = new FormControl('');
  options: Tenant[] = [];
  filteredOptions: Observable<Tenant[]>;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _apiService: RestApiService,
    private _tenantsService: TenantsService
  ) {}

  ngOnInit(): void {
    // Subscribe to tenant changes
    this._tenantsService
      .getTenantObservable()
      .subscribe((selectedTenant: Tenant) => {
        this.selectedTenant = selectedTenant;

        // Ensure components are checked for changes
        this._changeDetectorRef.markForCheck();
      });

    // Fetch tenants and select the first tenant automatically
    this._apiService.getTenants().subscribe((tenants: Tenant[]) => {
      this.tenants = tenants;
      this.options = tenants;
      this.filteredOptions = this.formControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || ''))
      );
      this._changeDetectorRef.markForCheck();

      if (this.tenants) {
        this._tenantsService.updateTenant(this.tenants[0]);
        this.formControl.setValue(this.tenants[0].description);
      }

      // Ensure components are checked for changes
      this._changeDetectorRef.markForCheck();
    });
  }

  private _filter(value: string | Tenant): Tenant[] {
    const filterValue =
      typeof value === 'string'
        ? value.toLowerCase()
        : value.description.toLowerCase();

    return this.tenants.filter(option => {
      const description = option.description.toLowerCase();
      return description.includes(filterValue);
    });
  }

  ngOnDestroy(): void {}

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedOption = event.option.value;
    this._tenantsService.updateTenant(selectedOption);

    this.selectedTenant = selectedOption;
    this.formControl.setValue(selectedOption.description);
  }

  clearInput(): void {
    this.formControl.setValue('');
  }

  onAutocompleteClosed(): void {
    this.formControl.setValue(this.selectedTenant.description);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
