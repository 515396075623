import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, take } from 'rxjs';
import { RestApiService } from './modules/rest/rest-api.service';
import { Tenant } from './modules/types/tenant.types';

@Injectable({
  providedIn: 'root',
})
export class UserRoleService {
  private data: string[];

  constructor(
    private apiService: RestApiService,
    private roles: UserRoles
  ) {}

  private fetchData(tenantId: string): Observable<string[]> {
    return this.apiService.getUserRoles(tenantId);
  }

  getData(tenantId: string): Promise<string[]> {
    if (this.data) {
      return new Promise(resolve => {
        resolve(this.data);
      });
    }

    return new Promise(resolve => {
      this.fetchData(tenantId)
        .pipe(
          take(1) //useful if you need the data once and don't want to manually cancel the subscription again
        )
        .subscribe((data: any) => {
          this.data = data;
          this.roles.updateRoles(data);
          resolve(data);
        });
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserRoles {
  private userRoles: ReplaySubject<string[]> = new ReplaySubject<string[]>(2);

  getRoles(): ReplaySubject<string[]> {
    return this.userRoles;
  }

  updateRoles(roles: string[]): void {
    this.userRoles.next(roles);
  }
}
