<form class="example-form">
  <mat-form-field
    style="min-width: 300px; border-radius: 100px"
    subscriptSizing="dynamic"
    (click)="clearInput()">
    <mat-icon svgIcon="heroicons_outline:office-building"></mat-icon>
    <span class="font-medium ml-2" style="min-width: 250px">
      <input
        type="text"
        matInput
        [formControl]="formControl"
        [matAutocomplete]="auto" />
    </span>
    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      (optionSelected)="onOptionSelected($event)"
      (closed)="onAutocompleteClosed()">
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option">
        {{ option.description }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
