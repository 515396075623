import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Tenant } from 'app/modules/types/tenant.types';
import { TenantsService } from '../tenants/tenants.service';

@Component({
  selector: 'phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'phone-number',
})
export class PhoneNumberComponent implements OnInit, OnDestroy {
  selectedTenant: Tenant;
  phoneNumberHref: string;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _tenantsService: TenantsService
  ) {}

  ngOnInit(): void {
    // Subscribe to tenant changes
    this._tenantsService
      .getTenantObservable()
      .subscribe((selectedTenant: Tenant) => {
        this.selectedTenant = selectedTenant;

        // Ensure components are checked for changes
        this._changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {}
}
