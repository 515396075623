import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'loginswitch' },

  // Redirect signed-in user to the '/example'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'loginswitch' },

  // Auth routes for guests
  {
    path: '',
    canMatch: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import(
            'app/modules/auth/confirmation-required/confirmation-required.module'
          ).then(m => m.AuthConfirmationRequiredModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import(
            'app/modules/auth/forgot-password/forgot-password.module'
          ).then(m => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            m => m.AuthResetPasswordModule
          ),
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then(
            m => m.AuthSignInModule
          ),
      },
    ],
  },
  // Auth routes for authenticated users
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            m => m.AuthSignOutModule
          ),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            m => m.AuthUnlockSessionModule
          ),
      },
      {
        path: 'forbidden',
        loadChildren: () =>
          import('app/modules/auth/forbidden/forbidden.module').then(
            m => m.ForbiddenModule
          ),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    canMatch: [AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'loginswitch',
        loadChildren: () =>
          import('app/modules/landing/loginSwitch/loginSwitch.module').then(
            m => m.LoginSwitchModule
          ),
      },
      {
        path: ':tenantId',
        data: {
          role: 'CUSTOMER_ADMIN',
        },
        loadChildren: () =>
          import('app/modules/landing/home/home.module').then(
            m => m.LandingHomeModule
          ),
      },
      {
        path: ':tenantId',
        canActivate: [AuthGuard],
        data: {
          role: 'CUSTOMER_ADMIN',
        },
        loadChildren: () =>
          import('app/modules/landing/question/question.module').then(
            m => m.LandingQuestionModule
          ),
      },
      {
        path: ':tenantId',
        canActivate: [AuthGuard],
        data: {
          role: 'CUSTOMER_ADMIN',
        },
        loadChildren: () =>
          import(
            'app/modules/landing/setting/setting-panel/setting-panel.module'
          ).then(m => m.SettingPanelModule),
      },
      {
        path: ':tenantId',
        data: {
          role: 'CHAT',
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/landing/chat/chat.module').then(
            m => m.ChatModule
          ),
      },
    ],
  },
  // Admin routes
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'example',
        loadChildren: () =>
          import('app/modules/admin/example/example.module').then(
            m => m.ExampleModule
          ),
      },
    ],
  },
];
