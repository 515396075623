import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationSettings } from 'app/modules/types/appConfiguration';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private appSettings: ApplicationSettings;

  constructor(private http: HttpClient) {}

  async loadAppConfigurations(): Promise<ApplicationSettings> {
    if (environment.production) {
      try {
        this.appSettings = await firstValueFrom(
          this.http.get<ApplicationSettings>('/config.prod.json')
        );

        return this.appSettings;
      } catch (error) {
        console.error(
          `Failed to load application settings. Error: ${JSON.stringify(error)}`
        );

        throw error;
      }
    } else {
      this.appSettings = environment;
      console.log(
        `Development App Settings: ${JSON.stringify(this.appSettings)}`
      );
    }
  }

  getAppSettings(): ApplicationSettings {
    return this.appSettings;
  }
}
