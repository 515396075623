import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { User } from 'app/core/user/user.types';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  private readonly LOCAL_STORAGE_KEY = 'currentUser';
  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
    this._user.subscribe(user => {
      if (!user) {
        this.loadUserFromLocalStorage();
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._user.next(value);
    if (value) {
      localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(value));
    }
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<User> {
    return this._httpClient.get<User>('api/common/user').pipe(
      tap(user => {
        this._user.next(user);
      })
    );
  }

  /**
   * Update the user
   *
   * @param user
   */
  update(user: User): Observable<any> {
    return this._httpClient.patch<User>('api/common/user', { user }).pipe(
      map(response => {
        this._user.next(response);
      })
    );
  }

  /**
   * Load the user from local storage if available
   */
  private loadUserFromLocalStorage(): void {
    const storedUser = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (storedUser) {
      this._user.next(JSON.parse(storedUser));
    }
  }
}
