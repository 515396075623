import { Component, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-hs-form-modal',
  template: `<div id="hs-form"></div>`,
  styles: [],
})
export class HsFormModalComponent implements AfterViewInit, OnDestroy {
  private scriptElement: HTMLScriptElement | null = null;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.loadScript();
  }

  loadScript() {
    this.scriptElement = this.renderer.createElement('script');
    this.scriptElement.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    // this.scriptElement.charset = "utf-8";
    this.scriptElement.type = 'text/javascript';
    this.renderer.listen(this.scriptElement, 'load', () => this.createForm());
    this.renderer.appendChild(document.body, this.scriptElement);
  }

  createForm() {
    (window as any).hbspt.forms.create({
      region: 'eu1',
      portalId: '27188818',
      formId: '918a2501-fa13-488e-9e42-38e049f28f2a',
      target: '#hs-form',
    });
  }

  ngOnDestroy(): void {
    if (this.scriptElement) {
      this.renderer.removeChild(document.body, this.scriptElement);
    }
  }
}
