import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { RestApiService } from 'app/modules/rest/rest-api.service';
import { TenantsService } from '../tenants/tenants.service';
import { Tenant } from 'app/modules/types/tenant.types';
import { Subject, takeUntil } from 'rxjs';
import { MinutesQuotaReponse } from 'app/modules/types/minutes-quota-response';

@Component({
  selector: 'app-quota-alert',
  templateUrl: './quota-alert.component.html',
  exportAs: 'quotaAlert',
})
export class QuotaAlertComponent implements OnInit, OnDestroy {
  tenant: Tenant;
  quota: MinutesQuotaReponse = null;
  freeMinutes: number;
  remainingMinutes: number;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _apiService: RestApiService,
    private _tenantService: TenantsService
  ) {}

  ngOnInit(): void {
    // Subscribe to tenant changes
    this._tenantService.getTenantObservable().subscribe(tenant => {
      this.tenant = tenant;
      // Only query noSubscription tenants to save resources
      if (tenant.noSubscription) {
        this._apiService
          .getQuota(tenant.name)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(response => {
            this.freeMinutes = response.freeMinutes;
            this.remainingMinutes = Math.max(
              response.freeMinutes - Math.ceil(response.usedMinutes),
              0
            );
            this.quota = response;
          });
      } else {
        this.quota = null;
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
